@import 'styles/common.scss';

.container {
    @include flex(stretch);
    position: relative;
    background-color: var(--ui-teal-light-bg-primary);
    padding: 16px 0;
    column-gap: 12px;
    border-top: 0.5px solid var(--border-border-10);

    .avatar-container {
        padding: 4px 0;
    }

    .header {
        @include flex(center, space-between);
        width: 100%;

        .name-and-duration {
            @include flex(center, flex-start);
            column-gap: 8px;
        }

        .name {
            @include ui-text-ui-15-semibold;
            color: var(--text_icon-primary);
        }

        .duration {
            @include ui-text-ui-14-regular;
            color: var(--text-icon-on-lights-tertiary);
        }

        .action-menu-button {
            button[aria-expanded="true"] {
                background-color: var(--surface-overlay-loud);
            }
        }

        .action-menu-list {
            min-width: 120px;
            width: 120px;
        }
    }

    .content {
        flex: 1;
    }

    .post-content {
        @include flex-column(flex-start);
        .images {
            margin-top: 12px;
        }
        .poll-options {
            width: 100%;
            &.has-content {
                margin-top: 12px;
            }
        }
        .post-document {
            min-width: 360px;
        }
    }

    .actions-stats-container {
        @include flex(center, flex-start);
        column-gap: 6px;
        margin-top: 4px;
        margin-left: -8px;

        .icon-button {
            padding: 8px;

            &.disabled {
                pointer-events: none;
                cursor: default;
            }

            &.icon-filled {
                svg > path {
                    fill: var(--surface-critical);
                }
            }

            .icon {
                height: 20px;
                width: 20px;
            }
        }
    }

    .stats {
        @include flex(center, flex-start);
        padding: 6px 0px;

        .stat {
            @include ui-text-ui-14-regular;
            color: var(--text-icon-on-lights-tertiary);
        }
    }
}
