@import "styles/common.scss";

.modal {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    max-width: 100vw;
    background-color: var(--surface-inverted);
    z-index: $zindex-modal;
    border-radius: 0;

    .action {
        position: absolute;
        background-color: var(--surface-primary);
        border-radius: 50%;
        color: #F4F6F8BD;
        z-index: $zindex-fixed;
        &.close {
            left: 16px;
            top: 16px;
            padding: 11px;
        }
        &.left {
            left: 16px;
        }
        &.right {
            right: 16px;
        }
        &.left, &.right {
            top: 50%;
            transform: translateY(-50%);
            padding: 11px;
        }
        &:hover {
            background-color: var(--surface-primary-hover);
        }
    }
    .fullscreen-image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 100vh;
        max-width: 100vw;
        object-fit: contain;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        user-select: none;
        &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
        }
    }
}
