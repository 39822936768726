@import "styles/common.scss";

.container {
    @include flex-column(null, flex-start);
    width: 100%;
    z-index: $zindex-modal;
    background-color: var(--ui-teal-light-bg-secondary);
}

.header {
    @include flex(null, space-between);
    position: sticky;
    top: 0;
    padding: 24px 48px 16px;
    background-color: var(--ui-teal-light-bg-secondary);
    z-index: $zindex-sticky;

    .breadcrumb {
        @include flex(center, flex-start);
        @include ui-text-ui-14-medium;
        color: var(--text-icon-interactive);
        column-gap: 4px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
    }

    .title {
        @include f-inter-bold(2.4rem, 3.6rem);
        color: var(--text-icon-on-lights-primary);
        margin-top: 2px;
    }
}

.content {
    margin: 0 auto;
    flex: 1;
    width: 680px;
    padding-bottom: 24px;
}

.post {
    margin-top: 20px;
}
