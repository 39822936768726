@import "styles/common.scss";

.container {
    position: relative;
    &.full-width {
        width: 100%;
    }
}

.icon {
    height: 20px;
    width: 20px;
}

.post-image {
    object-fit: cover;
}

.post-images-container {
    .add-another {
        margin-left: -15px;
        margin-top: 10px;
    }
}

.post-images {
    &.multiple {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row: auto auto;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
        .post-image {
            height: auto;
            width: 100%;
            aspect-ratio: 16/9;
        }
    }
    &.single {
        .post-image {
            max-width: 100%;
            &.is-landscape {
                width: 100%;
            }
            &.is-portrait {
                max-height: 432px;
            }
        }
    }
}

.post-image-container {
    position: relative;
    margin-bottom: 4px;
    display: inline-block;

    .post-image {
        border-radius: 12px;
        max-width: 100%;
        visibility: hidden;
        &.is-landscape, &.is-portrait {
            visibility: visible;
        }
        &.is-landscape {
            .post-image {
                width: 100%;
            }
        }
    }

    &.single {
      .is-portrait {
        max-width: 50%;
      }
    }

    .remove {
        position: absolute;
        top: 12px;
        right: 12px;
        border-radius: 50%;
        padding: 6px;
        color: var(--text_icon-secondary);
        background-color: var(--bg-primary-light);
    }
}
