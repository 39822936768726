@import 'styles/common.scss';

.container {
    @include flex(stretch);
    position: relative;
    background-color: var(--ui-teal-light-bg-primary);
    padding: 16px 20px 20px 20px;
    box-shadow: 0px 1px 3px 0px #080C0B0D;
    border-radius: 8px;
    column-gap: 12px;
    border-top: 0.5px solid var(--border-border-10);

    &.highlighted {
        scroll-margin-top: 120px;
    }

    .avatar-container {
        padding: 4px 0;
    }

    .header {
        @include flex(center, space-between);
        width: 100%;
        min-height: 28px;

        .name-and-duration {
            @include flex(center, flex-start);
            column-gap: 8px;
        }

        .name {
            @include ui-text-ui-15-semibold;
            color: var(--text_icon-primary);
        }

        .duration {
            @include ui-text-ui-14-regular;
            color: var(--text-icon-on-lights-tertiary);
        }

        .action-menu-button {
            button[aria-expanded="true"] {
                background-color: var(--surface-overlay-loud);
            }
        }

        .action-menu-list {
            min-width: 120px;
            width: 120px;
        }
    }

    .content {
        flex: 1;
    }

    .post-content {
        @include flex-column(flex-start);
        .images {
            margin-top: 12px;
        }
        .poll-options {
            width: 100%;
        }

        .poll-options, .post-documents {
            margin-top: 16px;
        }

        @include mobile {
            .post-documents {
                width: 100%;
            }
        }

        .post-document {
            @include mobile {
                width: 100%;
            }
            @include desktop {
                min-width: 360px;
            }
            margin-bottom: 12px;
        }
    }

    .actions {
        @include flex(center, flex-start);
        column-gap: 6px;
        margin-top: 4px;
        margin-left: -8px;

        .icon-button {
            padding: 8px;
            color: var(--text_icon-secondary);

            .icon {
                height: 20px;
                width: 20px;
            }

            &.disabled {
                pointer-events: none;
                cursor: default;
            }

            &.icon-filled {
                svg > path {
                    fill: var(--surface-critical);
                }
            }
        }
    }

    .stats {
        @include flex(center, flex-start);
        padding: 6px 0px;
        
        .stat {
            @include ui-text-ui-14-regular;
            color: var(--text-icon-on-lights-tertiary);

            &.clickable {
                cursor: pointer;
                &:hover {
                    color: var(--primary-color);
                    text-decoration: underline;
                }
            }
        }

        .dot-divider {
            height: 2px;
            width: 2px;
            margin: 0px 4px;
            background-color: var(--text-icon-on-lights-tertiary);
            border-radius: 50%;
        }
    }

    .replies {
        margin-top: 16px;
    }
}

.pending-actions {
    @include flex(center, flex-end);
    margin-top: 12px;
    column-gap: 8px;
}
