@import "styles/common.scss";

.container {
    @include flex(stretch);
    position: relative;
    background-color: var(--ui-teal-light-bg-primary);
    padding: 20px 0;
    column-gap: 12px;
    border-top: 0.5px solid var(--border-border-10);

    .avatar-container {
        padding: 4px 0;
    }

    .content {
        flex: 1;
    }

    .input {
        @include ui-text-ui-16-regular;
        width: 100%;
        border: none;
        padding: 6px 0;
        background-color: transparent;
        color: var(--text_icon-placeholder);
        text-align: left;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
}
