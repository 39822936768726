@import "styles/common.scss";

.container {
    position: relative;
    &.full-width {
        width: 100%;
    }
}
.icon {
    height: 20px;
    width: 20px;
}
.options {
    @include flex-column;
    .option {
        margin-bottom: 8px;
        .option-input {
            padding: 11.5px 16px;
            line-height: 22px;
        }
    }
    .add-option-button {
        border-style: dashed;
        margin-bottom: 8px;
    }
    .remove-option {
        border: 1px solid var(--text_icon-secondary);
        border-radius: 50%;
        padding: 4px;
    }
}
.actions {
    @include flex(center, flex-end);
    .remove {
        color: var(--text_icon-secondary);
    }
}
