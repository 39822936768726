@import "styles/common.scss";

.container {
    @include flex-center;
    @include mobile {
        height: 180px;
    }
    @include desktop {
        padding: 32px 0;
        .content {
            max-width: 400px;
        }
    }
    .content {
        @include flex-column(center, center);
    }
    .emoji {
        @include f-inter-bold(3.4rem, 4.6rem);
    }
    .message {
        @include ui-text-ui-13-medium;
        margin: 12px 0;
        text-align: center;
        color: var(--text_icon-secondary);
    }
}
