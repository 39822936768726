@import "styles/common.scss";

.container {
    @include flex(stretch);
    position: relative;
    background-color: var(--ui-teal-light-bg-primary);
    padding: 16px 20px 20px 20px;
    box-shadow: 0px 1px 3px 0px #080C0B0D;
    border-radius: 8px;
    column-gap: 12px;

    &.society-page {
        box-shadow: none;
    }

    .avatar-container {
        padding: 4px 0;
    }

    .content {
        flex: 1;
    }

    .input {
        @include ui-text-ui-16-regular;
        width: 100%;
        border: none;
        padding: 6px 0;
        background-color: transparent;
        color: var(--text_icon-placeholder);
        text-align: left;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }

    .post-attachment-actions {
        @include flex(center);
        column-gap: 6px;
        margin-top: 12px;
        margin-left: -8px;

        .icon-button {
            padding: 8px;
            color: var(--text_icon-secondary);
        }
        .icon {
            height: 20px;
            width: 20px;
        }
        @include mobile {
            .document-icon {
                display: none;
            }
        }
    }
}
