@import 'styles/common.scss';

.modal-container {
    position: relative;
    overflow-x: visible;
    border-radius: 16px;
}

.modal {
    @include flex-column;
    width: 460px;
    max-height: 95vh;

    .header {
        @include flex-center;
        position: relative;
        padding: 13px 0;
        background-color: var(--ui-teal-light-surface-tertiary);
        border-radius: 10px 10px 0px 0px;

        .title {
            @include f-inter-semi-bold(1.6rem, 2.2rem);
            color: var(--text-primary-v2);
        }

        .close {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 28px;
            width: 28px;
            padding: 6px;
        }
    }
    .footer {
        @include flex(center, flex-end);
        padding: 28px;
    }
}

.modal-content {
    @include flex-column;
    padding: 12px 28px 24px;
    column-gap: 12px;
    background-color: var(--ui-teal-light-surface-tertiary);

    .dropdown, .toggle {
        margin-bottom: 16px;
    }

    .toggle {
        label {
            @include ui-text-ui-14-medium;
        }
        input {
            margin-left: 0;
        }
    }

    .tooltip {
        @include f-inter-regular(1.3rem, 1.8rem);
        letter-spacing: -0.25px;

        &.require-post-approval-tooltip {
            width: 240px;
            left: -22px;
        }
        &.show-media-uploads-from {
            width: 320px;
        }
    }
    .show-media-uploads-from-tooltip-icon {
        height: 14px;
        width: 14px;
        margin-left: 4px;
        position: relative;
        top: 1.5px;
        color: var(--text-icon-tertiary);
        height: 100%;
    }

}
