@import "styles/common.scss";

.container {
    @include flex(center, space-between);
    position: relative;
    background-color: var(--surface-warning-silent);
    padding: 16px 20px;
    box-shadow: 0px 1px 3px 0px #0914250A;
    border-radius: 16px;
    column-gap: 12px;
    border: 0.5px solid var(--surface-overlay-loud);

    .content {
        @include flex(center, flex-start);
        @include ui-text-ui-16-semibold;
        column-gap: 10px;
    }
    .icon {
        height: 28px;
        width: 28px;
    }
}
