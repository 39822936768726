@import "styles/common.scss";

.post-document {
    @include flex(center, space-between);
    column-gap: 16px;
    padding: 16px 12px;
    background-color: var(--surface-overlay-silent);
    border-radius: 12px;
    &.downloadable {
        cursor: pointer;
    }
    &.full-width {
        width: 100%;
    }
}
.file-details {
    @include flex(center);
    column-gap: 10px;
    flex: 1;
    .name {
        @include ui-text-ui-14-medium;
        max-width: 400px;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.remove {
    padding: 9px;
    height: 32px;
    width: 32px;
    color: var(--text_icon-secondary);
    background-color: var(--bg-primary-light);
    box-shadow: 0px 1px 3px 0px #080C0B0D;
    &, &:hover {
        border-radius: 50%;
    }
}
