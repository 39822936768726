@import "styles/common.scss";

.container {
    position: relative;
    &.full-width {
        width: 100%;
    }
}

.icon {
    height: 20px;
    width: 20px;
}
