@import "styles/common.scss";

$image-size-small: 148px;
$image-size-large: 167px;

.container {
    display: grid;
    grid-row: auto auto;
    @include mobile {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 2px;
        grid-row-gap: 2px;
    }
    @include desktop {
        grid-column-gap: 4px;
        grid-row-gap: 4px;
        grid-template-columns: repeat(4, 1fr);
    }
    .image-container {
        position: relative;
        border: 0.5px solid var(--border-on-lights-10);
        cursor: pointer;
        display: inline-grid;

        &, .image {
            @include mobile {
                max-width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                object-position: center;
                border-radius: 0;
            }
            @include desktop {
                border-radius: 4px;
                &.small {
                    height: $image-size-small;
                    width: $image-size-small;
                }
                &.large {
                    height: $image-size-large;
                    width: $image-size-large;
                }
            }
        }
        .image {
            object-fit: cover;
            object-position: center;
        }
        .action-menu {
            position: absolute;
            top: 8px;
            right: 8px;
            border-radius: 50%;
            cursor: pointer;
            z-index: $zindex-dropdown;
            background-color: var(--ui-teal-light-bg-primary);
            opacity: 0;
            display: none;

            &[data-headlessui-state="open"] {
                opacity: 1;
            }
        }
        &:hover .action-menu {
            opacity: 1;
            transition: opacity 0.25s ease-in;
        }

        .action-menu-button {
            button {
                padding: 9px;
                &[aria-expanded="true"] {
                    background-color: var(--ui_teal_light-surface-primary-pressed);
                    border-radius: 50%;
                }
                div {
                    height: 14px;
                    width: 14px;
                }
            }
        }
        .action-menu-list {
            min-width: 168px;
        }
    }
}

.no-media {
    @include ui-text-ui-16-medium;
    text-align: center;
    padding-top: 20px;
}
