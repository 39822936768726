@import 'styles/common.scss';

.modal-wrapper {
    @include mobile {
        align-items: flex-start;
        transition: all 50ms ease-in-out;
        bottom: unset;
    }
}

.modal-container {
    position: relative;
    overflow-x: visible;
    border-radius: 16px;

    @include mobile {
        width: 100vw;
        height: 100%;
        border-radius: 20px 20px 0 0;
    }
}

.modal {
    @include flex-column;
    @include mobile {
        height: 100%;
    }
    @include desktop {
        width: 640px;
        max-height: 90vh;
    }
    .header {
        @include flex-center;
        position: relative;
        padding: 15px 0;

        .title {
            @include f-inter-semi-bold(1.6rem, 2.2rem);
            color: var(--text-primary-v2);
        }

        .close {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 28px;
            width: 28px;
            padding: 7px;
            border-radius: 50%;
            color: var(--text_icon-secondary);

            @include mobile {
                background-color: var(--ui-teal-light-surface-overlay-silent);
                right: 16px;
                top: 16px;
                padding: 12px;
                height: 36px;
                width: 36px;
            }
        }
    }
    .banner {
        padding: 0 20px;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .footer {
        @include mobile {
            @include flex(flex-end, space-between);
            padding: 12px 16px;
            flex: 1;
        }
        @include desktop {
            @include flex(center, space-between);
            padding: 28px 28px 28px 16px;
        }
        .dropdown {
            width: auto;


            .dropdown-button {
                span {
                    @include ui-text-ui-14-semibold;
                    color: var(--text_icon-secondary);
                }
                &:hover {
                    background-color: var(--surface-overlay-silent);
                    border: none !important;
                }
                box-shadow: none !important;
            }

            .dropdown-options {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .who-can-reply-tooltip-child:has( > .dropdown[data-headlessui-state="open"]) + .tooltip {
            display: none;
        }

        .post-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

.modal-content {
    @include flex;
    column-gap: 12px;
    overflow: auto;
    @include mobile {
        padding: 16px;
    }
    @include desktop {
        padding: 12px 28px 24px;
    }
    .post-content {
        @include flex-column;
        flex: 1;
    }

    .post-text {
        @include flex(flex-start, flex-start);
        width: 100%;
    }

    .post-text-input {
        flex: 1;
    }

    .post-attachment-actions {
        @include flex(center, flex-start);
        column-gap: 6px;
        margin-top: 12px;

        &.no-attachments {
            margin-left: -8px;
        }

        .icon-button {
            padding: 8px;
            color: var(--text_icon-secondary);

            .icon {
                height: 20px;
                width: 20px;
            }
        }
        @include mobile {
            .document-uploader {
                display: none;
            }
        }
    }
}

.tooltip {
    @include f-inter-regular(1.3rem, 1.8rem);
    letter-spacing: -0.25px;

    &.who-can-reply-tooltip {
        width: 320px;
    }
}
