@import "styles/common.scss";

.container {
    .poll-option {
        @include flex(center, space-between);
        position: relative;
        margin-bottom: 8px;
        padding: 13px 16px;
        width: 100%;
        border-radius: 12px;
        border: 1.5px solid var(--border-border-15);
        cursor: pointer;
        &.disabled {
            cursor: default;
        }
        .text {
            word-break: break-word;
        }
        .text, .percentage {
            @include ui-text-ui-16-semibold;
            z-index: 1;
        }
        .image-percentage {
            @include flex(center);
            column-gap: 8px;
        }
        .user-image {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
        }
        .background {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 300px;
            background-color: var(--ui_teal_light-surface-primary-hover);
            border-radius: 10px;
        }
        &.top {
            border-color: var(--primary-color);
            .background {
                background-color: var(--surface-brand-silent);
            }
            .text, .percentage {
                color: var(--primary-color);
            }
        }
    }
}
