@import 'styles/common.scss';

.avatar-container {
    @include flex-center;
    position: relative;
    border: 1px solid var(--bg-primary-light);
    border-radius: 50%;
    .pointer-cursor{
    cursor: pointer;}
    &.xsmall {
        height: 28px;
        width: 28px;
        min-width: 28px;
        border-width: 0;

        .avatar {
            border-width: 0;
            padding: 0;
        }
    }

    &.small {
        height: 32px;
        width: 32px;
        min-width: 32px;

        .avatar {
            height: 28px;
            width: 28px;
            min-width: 28px;
        }
    }

    &.medium {
        height: 40px;
        width: 40px;
        min-width: 40px;

        .avatar {
            height: 36px;
            width: 36px;
            min-width: 36px;
        }
    }

    &.large {
        height: 48px;
        width: 48px;
        min-width: 48px;

        .avatar {
            height: 44px;
            width: 44px;
            min-width: 44px;
        }
    }

    &.size80 {
        height: 80px;
        width: 80px;
        min-width: 80px;

        .avatar {
            height: 78px;
            width: 78px;
            min-width: 78px;
        }
    }

    .avatar {
        color: var(--primary-color);
        border: 1px solid var(--border-border-10);
        padding: 2px;
    }

    .verified-tag {
        position: absolute;
        bottom: 0;
        right: 0;
        color: var(--primary-color);
        border: 1px solid var(--bg-primary-light);
        border-radius: 50%;
    }
}
