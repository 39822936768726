@import "styles/common.scss";

$doc-size-small: 198px;
$doc-size-large: 224px;

.container {
    @include mobile {
        @include flex-column;
    }
    @include desktop {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row: auto auto;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
    }

    .post-doc {

        @include mobile {
            @include flex(center);
            width: 100%;
            column-gap: 10px;
            padding: 16px;
            border-radius: 12px;
            margin-bottom: 4px;
        }
        @include desktop {
            @include flex-column(center);
            height: 167px;
            border-radius: 4px;
            padding: 44px 10px 12px;
            &.small {
                width: $doc-size-small;
            }
            &.large {
                width: $doc-size-large;
            }
        }
        background-color: var(--ui-teal-light-surface-overlay-silent);
        cursor: pointer;

        .name {
            @include ui-text-ui-14-medium;
            @include multi-line-ellipsis(2);
            margin-top: 10px;
            color: var(--text_icon-primary);
            text-align: center;
        }
    }
}

.no-docs {
    @include ui-text-ui-16-medium;
    text-align: center;
    padding-top: 20px;
}
