@import "styles/common.scss";

.container {
    position: relative;
    border-radius: 10px;
    @include mobile {
        width: calc(100vw - 32px);
    }
    @include desktop {
        width: 460px;
    }

    .cancel-button {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 28px;
        width: 28px;
        padding: 7px;
        border-radius: 50%;

        @include mobile {
            background-color: var(--ui-teal-light-surface-overlay-silent);
            padding: 12px;
            height: 36px;
            width: 36px;
        }
    }
    .content {
        @include desktop {
            background-color: var(--bg-primary-webapp);
        }
    }

    .header {
        padding: 13px 20px;

        .title {
            @include ui-text-ui-16-semibold;
            text-align: center;
        }
    }
    .description-container {
        @include mobile {
            padding: 8px 20px;
        }
        @include desktop {
            padding: 12px 28px 32px 28px;
        }
    }
    .description {
        @include f-inter-regular(1.4rem, 2.2rem);
        color: var(--text_icon-secondary);
    }
    .divider {
        border-width: 0;
        margin: 8px 0;
    }
    .footer {
        @include flex(center, flex-end);
        column-gap: 8px;
        padding: 28px;

        @include mobile {
            padding: 16px 20px;
            .action-button {
                flex: 1;
            }
        }
    }
}
