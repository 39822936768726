@import 'styles/common.scss';

.modal-wrapper {
    @include mobile {
        align-items: flex-start;
        transition: all 50ms ease-in-out;
        top: unset;

        &.input-focussed {
            position: absolute;
        }
    }
}

.modal-container {
    position: relative;
    overflow-x: visible;
    border-radius: 16px;

    @include mobile {
        width: 100vw;
        height: 100%;
        border-radius: 20px 20px 0 0;
    }
}

.modal {
    @include flex-column;
    @include mobile {
        height: 100%;
    }
    @include desktop {
        width: 640px;
        max-height: 90vh;
    }
    .header {
        @include flex-center;
        position: relative;
        padding: 15px 0;

        .title {
            @include f-inter-semi-bold(1.6rem, 2.2rem);
            color: var(--text-primary-v2);
        }

        .close {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 28px;
            width: 28px;
            padding: 7px;
            border-radius: 50%;
            color: var(--text_icon-secondary);

            @include mobile {
                background-color: var(--ui-teal-light-surface-overlay-silent);
                right: 16px;
                top: 16px;
                padding: 12px;
                height: 36px;
                width: 36px;
            }
        }
    }
    .footer {
        @include mobile {
            @include flex(flex-end, flex-end);
            padding: 12px 16px;
            flex: 1;
        }
        @include desktop {
            @include flex(center, flex-end);
            padding: 28px 28px 28px 16px;
        }
        .reply-button {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

.modal-content {
    @include flex-column;
    overflow: auto;
    @include mobile {
        padding: 16px;
    }
    @include desktop {
        padding: 12px 28px 24px;
    }

    .post {
        box-shadow: none;
        padding: 8px 0;
    }

    .post-reply {
        @include flex;
        width: 100%;
        column-gap: 12px;
    }

    .reply-content {
        @include flex-column;
        flex: 1;
    }


    .reply-text {
        @include flex(flex-start, flex-start);
        width: 100%;
    }

    .reply-text-input {
        flex: 1;
    }

    .post-reply-attachment-actions {
        @include flex(center, flex-start);
        column-gap: 6px;
        margin-top: 12px;

        &.no-attachments {
            margin-left: -8px;
        }

        .icon-button {
            padding: 8px;

            .icon {
                height: 20px;
                width: 20px;
            }
        }
    }
}
