@import "styles/common.scss";

.info-container {
    @include flex(center, space-between);
    background-color: var(--ui-teal-light-surface-overlay-silent);
    border-radius: 12px 16px;
    margin-top: 12px;
    column-gap: 8px;
    @include mobile {
        padding: 12px;
    }
    @include desktop {
        padding: 12px 16px;
    }
    .info {
        color: var(--text-icon-on-lights-secondary);
    }
    .icon-button {
        padding: 5px;
    }
    .info-icon {
        align-self: flex-start;
    }
    .info-icon, icon-button {
        color: var(--text-icon-on-lights-secondary);
    }
    .title-icon {
        @include flex(center);
        column-gap: 8px;
    }
}
