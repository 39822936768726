@import "styles/common.scss";

.container {
    position: relative;
    &.multiple {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row: auto auto;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
        width: 100%;
        .image {
            height: auto;
            width: 100%;
            aspect-ratio: 16/9;
            cursor: pointer;
        }
    }
    &.single {
        .image {
            max-width: 100%;
            max-height: 432px;
            &.is-landscape {
                width: 100%;
            }
        }
    }
    .image-container {
        position: relative;
        .image {
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
        }
        &.last {
            .image {
                filter: brightness(0.5);
            }
            .additional-images {
                @include f-inter-bold(2.4rem, 3.2rem);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--text-icon-text-icon-inverted-primary);
                pointer-events: none;
            }
        }
    }
}
