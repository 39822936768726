@import 'styles/common.scss';

.input {
    color: var(--text-primary-v2);
    padding: 6px 0;
    width: 100%;
    height: auto;
    caret-color: var(--primary-color);
    word-break: break-word;

    &, &::before {
        @include f-inter-regular(1.6rem, 2.6rem);
    }

    &::before {
        content: attr(placeholder);
        color: var(--text-icon-placeholder);
    }

    &:focus {
        outline: none;
    }
}
